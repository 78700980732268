import StudentAlya from "public/assets/home/testimonial/student_alya.svg";
import StudentAlya2 from "public/assets/home/testimonial/student_alya2.svg";
import OrangtuaNoufal from "public/assets/home/testimonial/orangtua_noufal.svg";
import StudentAmanda from "public/assets/home/testimonial/student_amanda.svg";

export const testimonials = [
  {
    id: 1,
    image: StudentAlya2,
    relatives: "Murid",
    name: "Alya G",
    relation: "SMA Kelas 12",
    location: "",
    title:
      "Aku cuma les di CoLearn tapi nilaiku meningkat dan akhirnya lolos SNBP",
    description:
      "Guru-gurunya asik, nggak bikin bosen, dan bikin pelajaran gampang dimengerti. Kalau kita kesulitan, guru pasti bantu kita. Ada PDF dan video yang bisa dibaca juga sehabis kelas. ",
    stars: 5
  },
  {
    id: 2,
    image: OrangtuaNoufal,
    relatives: "Orangtua",
    name: "Orangtua Naufal",
    relation: "SMP Kelas 9",
    location: "",
    title: "CoLearn mengedepankan komunikasi dengan orangtua dan murid",
    description:
      "Anak saya pendiam sebelumnya, sekarang enjoy dan aktif berinteraksi dengan Guru CoLearn. Ini artinya, salah satu tujuan penting pendidikan anak sudah tercapai. Jika sekedar belajar online, pilihan lain mungkin ada, namun hanya CoLearn yang selalu memberi arahan dan mengedepankan komunikasi pada orangtua dan murid. Thanks a lot, CoLearn!",
    stars: 5
  },
  {
    id: 3,
    image: StudentAlya,
    relatives: "Murid",
    name: "Alya H",
    relation: "SMP Kelas 8",
    location: "",
    title: "Nilai Matematika PTS-ku 100 dan nilai rapot 98!",
    description:
      "Alhamdulillah, PTS kemarin aku dapet nilai matematika 100 dan nilai rapot 98, soalnya belajar di Bimbel CoLearn bikin aku jadi lebih ngerti pelajaran.",
    stars: 5
  },
  {
    id: 4,
    image: StudentAmanda,
    relatives: "Murid",
    name: "Amanda Dwi",
    relation: "SMP Kelas 9",
    location: "",
    title: "Cara ngajarnya bikin aku ngerti banget.",
    description:
      "Aku jadi ngerti materi duluan, soalnya ada materi yang belum diajarin di sekolah tapi udah diajarin di bimbel online CoLearn. Cara ngajar gurunya bikin matematika, fisika, kimia cepet dimengerti. ",
    stars: 5
  }
];
