import styled from "styled-components";
import { IStyledLabelProp } from "./rating.types";

export const StyledRatings = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledLabel = styled("label")<IStyledLabelProp>`
  width: 15px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.yellow[500] : theme.colors.neutral[100]};

  :before {
    content: "★";
  }
`;
