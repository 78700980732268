import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";

export const LeadGenerationSectionWrapper = styled.section`
  position: relative;
  background: radial-gradient(
    circle,
    rgba(21, 69, 211, 0.7) 0%,
    rgba(26, 88, 241, 1) 30%,
    rgba(25, 72, 205, 1) 100%
  );
`;

export const LeadGenerationSectionContainer = styled.div`
  padding: 52px 0;
  display: flex;
  gap: ${rem(32)};
  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 0;
    padding: 0 16px 56px;
  }
  @media ${breakpoints.tablet} {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media ${breakpoints.laptop} {
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const LeadGenerationImgWrapper = styled.figure`
  margin: 0;
  position: relative;
  @media ${breakpoints.mobile} {
    flex-basis: 100%;
  }
  @media ${breakpoints.tablet} {
    flex-basis: 30%;
  }
  @media ${breakpoints.laptop} {
    flex-basis: 40%;
  }
`;

export const LeadGenerationTextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  position: relative;
  padding: ${rem(64)} ${rem(32)};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${breakpoints.mobile} {
    padding: 16px;
    min-height: 240px;
    flex-basis: 100%;
    z-index: 1;
    transform: translateY(-40px);
    justify-content: flex-start;
  }
  @media ${breakpoints.tablet} {
    flex-basis: 50%;
  }
  @media ${breakpoints.laptop} {
    flex-basis: 45%;
  }
`;

export const LeadGenerationTextContainer = styled.div`
  max-width: 60%;
  font-family: ${({ theme }) => theme.fonts.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  .subHeading {
    font-weight: 700;
    line-height: 28px;
    font-size: ${({ theme }) => theme.fontSizes.lg};
    color: ${({ theme }) => theme.colors.neutral["700"]};
    margin: 0;
  }

  .heading {
    font-weight: 700;
    line-height: 36px;
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    color: ${({ theme }) => theme.colors.neutral["1000"]};
    margin: 0;
  }

  @media ${breakpoints.mobile} {
    max-width: 100%;
    justify-content: flex-start;
    text-align: center;
    gap: 0px;
    .subHeading {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }
    .heading {
      font-size: ${rem(28)};
    }
  }
`;

export const InfoPoint = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.brand[500]};
  margin: 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }
`;

export const LeadGenerationPriceInfoContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 60px);

  @media ${breakpoints.tablet} {
    left: unset;
    bottom: unset;
    right: 0;
    top: 50%;
    transform: translate(30%, -50%);
  }
`;
