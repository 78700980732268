import {
  BimbelCTALink,
  StyledCardWrapper,
  StyledCarouselWrapper,
  StyledReviewSection,
  StyledTitle
} from "./review-section.styles";
import { IReviewSectionProps } from "./review-section.types";
import { ScrollSnapSlider } from "components/web-checkout/scroll-snap-carousel/scroll-snap-carousel";
import { TestimonialCard } from "../testimonial-card/testimonial-card";
import { Container } from "styles/common/container";
import { IComponentWithSource } from "types/common/common.types";

export const ReviewSection: React.FC<
  IReviewSectionProps & IComponentWithSource
> = (props) => {
  const { testimonials, title, width, eventSource } = props;
  return (
    <StyledReviewSection>
      <Container>
        <StyledTitle>{title}</StyledTitle>
        <StyledCarouselWrapper>
          <ScrollSnapSlider eventSource={eventSource}>
            {testimonials.map((obj) => (
              <StyledCardWrapper key={obj.id}>
                <TestimonialCard width={width} data={obj} />
              </StyledCardWrapper>
            ))}
          </ScrollSnapSlider>
          <BimbelCTALink href="/bimbel" data-testid="daftar-bimbel-button">
            Daftar
          </BimbelCTALink>
        </StyledCarouselWrapper>
      </Container>
    </StyledReviewSection>
  );
};
