import { Rating } from "components/rating/rating";
import React from "react";
import {
  StyledAvatar,
  StyledContent,
  StyledDescription,
  StyledFooter,
  StyledFooterSpan,
  StyledHeader,
  StyledImageWrapper,
  StyledHeaderContentWrapper,
  StyledTitle,
  StyledTestimonialCard,
  StyledHeaderLabel
} from "./testimonial-card.styles";
import { ITestimonialCardProps } from "./testimonial.types";

export const TestimonialCard: React.FC<ITestimonialCardProps> = ({
  data,
  width
}) => {
  const {
    image,
    relatives,
    name,
    relation,
    location,
    title,
    description,
    stars
  } = data;
  return (
    <StyledTestimonialCard $width={width}>
      <StyledHeader>
        <StyledImageWrapper>
          {
            <StyledAvatar
              src={image}
              alt="avatar"
              width="64"
              height="64"
              objectFit="cover"
            />
          }
        </StyledImageWrapper>
        <StyledHeaderContentWrapper>
          {
            <StyledHeaderLabel
              $isHighlighted={relatives.toLowerCase() === "murid"}
            >
              {relatives}
            </StyledHeaderLabel>
          }
          {<Rating count={stars} />}
        </StyledHeaderContentWrapper>
      </StyledHeader>
      <StyledContent>
        <StyledTitle $isBold $lineClamp={3}>{`“${title}”`}</StyledTitle>
        <StyledDescription $lineClamp={5}>{description}</StyledDescription>
      </StyledContent>
      <StyledFooter>
        <div>
          <StyledFooterSpan $isBold $isHighlighted>
            {name}
          </StyledFooterSpan>
          <StyledFooterSpan $isHighlighted>{relation}</StyledFooterSpan>
        </div>
        <StyledFooterSpan>{location}</StyledFooterSpan>
      </StyledFooter>
    </StyledTestimonialCard>
  );
};
