import styled from "styled-components";

export const PriceInfoCardWrapper = styled.div`
  padding: 16px;
  width: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 7.12386px rgba(0, 0, 0, 0.16);
  text-align: center;
  p {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: 20px;
    margin: 4px 0;
  }
`;

export const GuaranteeBannerContainer = styled.div`
  position: relative;
  margin: 0 0 8px -16px;
  height: 60px;
  box-sizing: border-box;
`;

export const GuaranteeBannerImgContainer = styled.figure`
  position: absolute;
  top: 0;
  height: 100%;
  width: 356px;
  left: -28px;
  right: -28px;
  margin: 0;
`;
