import { Button } from "components/atoms/button/button";
import Image from "next/legacy/image";
import React from "react";
import {
  GuaranteeBannerContainer,
  GuaranteeBannerImgContainer,
  PriceInfoCardWrapper
} from "./price-info-card.styles";

interface IPriceInfoCardProps {
  onCTAClick: () => void;
  ctaTitle?: string;
  heading?: string;
}

export const PriceInfoCard: React.FC<IPriceInfoCardProps> = ({
  onCTAClick,
  ctaTitle,
  heading
}) => {
  return (
    <PriceInfoCardWrapper>
      <p>{heading}</p>
      <GuaranteeBannerContainer>
        <GuaranteeBannerImgContainer>
          <Image
            src="/assets/lead-generation/guarantee-banner.svg"
            layout="fill"
            priority
            alt="Garansi uang kembali 100%"
          />
        </GuaranteeBannerImgContainer>
      </GuaranteeBannerContainer>
      <Button
        variant="primary"
        isFullWidth
        onClick={onCTAClick}
        data-testid="info-bimbel-banner-button"
      >
        {ctaTitle}
      </Button>
    </PriceInfoCardWrapper>
  );
};
