import styled from "styled-components";
import { breakpoints, sizes } from "styles";
import { Container } from "styles/common/container";
export const StyledReviewSection = styled.section`
  position: relative;
  width: 100%;
  background: url("/assets/home/testimonial/home-testimonial-stars1.svg") left
      18px top 65px /210px no-repeat,
    url("/assets/home/testimonial/home-testimonial-stars2.svg") right 0px top
      29px /230px no-repeat;
  background-color: ${({ theme }) => theme.colors.yellow[100]};
  height: ${sizes.reviewSectionHeight.mobile};

  @media ${breakpoints.tablet} {
    height: ${sizes.reviewSectionHeight.tablet};
    background: url("/assets/home/testimonial/home-testimonial-stars1.svg") left
        50px top 50px /280px no-repeat,
      url("/assets/home/testimonial/home-testimonial-stars2.svg") right 0px top
        29px /350px no-repeat;
    background-color: ${({ theme }) => theme.colors.yellow[100]};
  }

  ${Container} {
    overflow-x: visible;
  }
`;

export const StyledTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral[900]};
  text-align: center;
  padding-top: 40px;
  @media ${breakpoints.tablet} {
    padding-top: 88px;
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
  }
`;

export const StyledCardWrapper = styled.li`
  scroll-snap-align: center;
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 9px rgba(110, 116, 134, 0.05);
  border-radius: 12px;
`;

export const StyledCarouselWrapper = styled.div`
  background: url("/assets/home/testimonial/neco-peeking-tiptoe_2.svg") left 1px
    top 20px /100px no-repeat;

  @media ${breakpoints.tablet} {
    padding-top: 70px;
    background: url("/assets/home/testimonial/neco-peeking-tiptoe_2.svg") left
      1px top 32px /115px no-repeat;
  }

  @media ${breakpoints.laptop} {
    background: url("/assets/home/testimonial/neco-peeking-tiptoe_2.svg") left
      60px top 8px /130px no-repeat;
  }
`;

export const BimbelCTALink = styled.a`
  margin: 32px auto;
  display: block;
  max-width: 328px;
  background-color: ${({ theme }) => theme.colors.brand[500]};
  color: ${({ theme }) => theme.colors.white};
  padding: 12px 24px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
  text-align: center;
`;
