import React from "react";
import { StyledLabel, StyledRatings } from "./rating.styles";
import { IRating } from "./rating.types";

export const Rating: React.FC<IRating> = ({ count }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    i <= count
      ? stars.push(
          <StyledLabel key={"star" + i} aria-label="star" $isHighlighted />
        )
      : stars.push(
          <StyledLabel
            key={"star" + i}
            aria-label="star"
            $isHighlighted={false}
          />
        );
  }
  return <StyledRatings>{stars}</StyledRatings>;
};
