import { sizes } from "styles";
import { breakpoints } from "styles";
import styled from "styled-components";
import Image from "next/legacy/image";
import { IStyledTitleProps, IStyledSpanProps } from "./testimonial.types";

export const StyledTestimonialCard = styled.div<{ $width: string }>`
  @media ${breakpoints.laptop} {
    width: ${({ $width }) => $width};
    height: 400px;
  }
  width: ${sizes.testimonialCard.mobile.width};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${sizes.testimonialCard.mobile.height};
  padding: 24px;
`;

export const StyledAvatar = styled(Image)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.pink[500]};
`;

export const StyledHeader = styled.header`
  display: flex;
  margin: 8px 0 5px;
`;

export const StyledImageWrapper = styled.div`
  height: 70px;
`;
export const StyledHeaderContentWrapper = styled.div`
  margin-left: 8px;
`;
export const StyledContent = styled.div`
  width: 100%;
`;

export const StyledTitle = styled("p")<IStyledTitleProps>`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ $isBold }) => $isBold && "bold"};
  line-height: 22px;
  text-align: initial;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: ${({ $lineClamp }) => $lineClamp};
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  margin: 0 0 10px 0;
`;

export const StyledDescription = styled(StyledTitle)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const StyledFooter = styled.footer`
  background: url("/assets/home/testimonial/radhe-stars.svg") right 70px bottom
    0px/ 28px no-repeat;
  width: 100%;
  position: absolute;
  bottom: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledSpan = styled("span")<IStyledSpanProps>`
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 22px;
  font-weight: ${({ $isBold }) => $isBold && "bold"};
  text-align: initial;
  padding: 0 10px 10px 0;
  border-radius: 8px;
  height: 10px;
`;

export const StyledHeaderLabel = styled(StyledSpan)`
  background-color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.yellow[500] : theme.colors.brand[500]};
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.brand[500] : theme.colors.white};
  padding: 10px;
  font-weight: bold;
`;

export const StyledFooterSpan = styled(StyledSpan)`
  background-color: unset;
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.black : theme.colors.neutral[600]};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 20px;
`;
