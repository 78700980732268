import Image from "next/legacy/image";
import { useRouter } from "next/router";
import LeadGenerationHeroImg from "public/assets/lead-generation/lead-generation-hero-2.png";
import LeadGenerationHeroMWebImg from "public/assets/lead-generation/lead-generation-hero-mweb-2.png";
import React from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { bannerIntentEventNames } from "services/mixpanel/tanya-events";
import { getLeadSourceFromPath } from "utils/utils";
import { PriceInfoCard } from "../price-info-card/price-info-card";
import {
  InfoPoint,
  LeadGenerationImgWrapper,
  LeadGenerationPriceInfoContainer,
  LeadGenerationSectionContainer,
  LeadGenerationSectionWrapper,
  LeadGenerationTextContainer,
  LeadGenerationTextWrapper
} from "./hero-lead-generation-section.styles";
import { useMediaQuery } from "hooks/use-media-query/use-media-query";

interface IHeroLeadGenerationSectionProps {
  onPriceInfoCTAClick: () => void;
  buttonCTAText?: string;
}

export const HeroLeadGenerationSection: React.FC<
  IHeroLeadGenerationSectionProps
> = ({ onPriceInfoCTAClick, buttonCTAText }) => {
  const isMobile = useMediaQuery("mobile");

  const router = useRouter();

  const leadSource = getLeadSourceFromPath(router.pathname);

  const handleCTAClick = () => {
    trackEvent({
      eventName: bannerIntentEventNames.primaryCTAClicked,
      payload: {
        leadSource
      }
    });
    onPriceInfoCTAClick();
  };
  return (
    <LeadGenerationSectionWrapper>
      <LeadGenerationSectionContainer>
        <LeadGenerationImgWrapper>
          <Image
            src={isMobile ? LeadGenerationHeroMWebImg : LeadGenerationHeroImg}
            alt="Bimbel online mulai 95.000 / bulan"
            layout="responsive"
            sizes="(min-width: 768px) 33vw, 80vw"
            priority
          />
        </LeadGenerationImgWrapper>
        <LeadGenerationTextWrapper>
          <LeadGenerationTextContainer>
            <p className="subHeading">Aplikasi belajar online</p>
            <div>
              <p className="heading">
                Bimbel online <br /> mulai 95.000 / bulan
              </p>
            </div>
            <InfoPoint>Cepet ngerti Matematika.</InfoPoint>
          </LeadGenerationTextContainer>
          <LeadGenerationPriceInfoContainer>
            <PriceInfoCard
              onCTAClick={handleCTAClick}
              ctaTitle={buttonCTAText ? buttonCTAText : "Minta info Bimbel"}
              heading="Kalau nggak cocok:"
            />
          </LeadGenerationPriceInfoContainer>
        </LeadGenerationTextWrapper>
      </LeadGenerationSectionContainer>
    </LeadGenerationSectionWrapper>
  );
};
